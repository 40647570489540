import { createSlice } from "@reduxjs/toolkit";
import { getProjectThunk } from "../../thunk";

const formSlice = createSlice({
  name: 'project',
  initialState: {
    list: null,
    loading: false,
    error: null,
  },
  extraReducers: {
    [getProjectThunk.pending]: state => {
      state.loading = true;
    },
    [getProjectThunk.fulfilled]: (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.list = data;
    },
    [getProjectThunk.rejected]: state => {
      state.loading = false;
    },
  }
})

// Extract the action creators object and the reducer
const { reducer } = formSlice;
// Export the reducer, either as a default or named export
export default reducer;