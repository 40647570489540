import React, { useEffect, useRef } from 'react';

const ResponsiveButton = ({open, setOpen}) => {
  const block1 = useRef();
  const block2 = useRef();
  const block3 = useRef();

  useEffect(()=>{
    if(open) {
      block1.current.classList.add("rotate-45", "top-2/5");
      block2.current.classList.add("opacity-0");
      block3.current.classList.add("-rotate-45", "top-2/5");
      block3.current.classList.remove("top-4/5");
    } else {
      block1.current.classList.remove("rotate-45", "top-2/5");
      block2.current.classList.remove("opacity-0");
      block3.current.classList.remove("-rotate-45", "top-2/5");
      block3.current.classList.add("top-4/5");
    }
  }, [open])
  return (
    <div id="nav-button" className="relative w-9 h-6 cursor-pointer" onClick={()=>setOpen(!open)}>
      <span ref={block1} className="block absolute h-1/5 w-full top-0 bg-gray-100 transform rounded-sm transition-all duration-500 ease-in-out"/>
      <span ref={block2} className="block absolute h-1/5 w-full top-2/5 bg-gray-100 transform rounded-sm transition-all duration-500 ease-in-out "/>
      <span ref={block3} className="block absolute h-1/5 w-full top-4/5 bg-gray-100 transform rounded-sm transition-all duration-500 ease-in-out"/>
    </div>
  )
}


export default ResponsiveButton