import React, { useEffect, useRef } from 'react';
import person from 'assets/person.png';
import Fade from 'react-reveal/Fade';
import AboutMe from 'views/AboutMe';

const Home = ({ goingToAbout, falseAbout}) => {
  console.log(goingToAbout)
  const aboutRef = useRef();
  const toAbout = () => {
    if (aboutRef.current) 
      aboutRef.current.scrollIntoView({behavior: 'smooth'});
  }
  useEffect(() => {
    if (goingToAbout && aboutRef.current) {
      toAbout()
      falseAbout();
    }

  }, [goingToAbout, falseAbout])
  
  return (
    <div className="bg-gradient-to-br from-bg-1 to-bg-2 pt-10 min-h-screen">
      <section className="text-gray-100 body-font">
        <div className="container flex flex-col items-center px-5 py-16 mx-auto lg:px-20 lg:py-24 md:flex-row">
          <div className="flex flex-col items-start self-start w-full pt-0 mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:items-start md:text-left md:mb-0 lg:text-center">
            {/* <h2 className="mb-1 text-xs font-medium tracking-widest text-blue-500 title-font">
              Your tagline
            </h2> */}
            <Fade left cascade>
              <div>
              <h1 className="mb-8 text-4xl font-bold tracking-tighter text-left text-gray-100 md:text-5xl title-font">
                Hello
                <br />
                my name <span className="text-purple-600 text-5xl md:text-6xl">Leo</span>
              </h1>
              <p className="mb-8 md:pl-3 text-base leading-relaxed text-left text-gray-100 lg:text-1xl">
                  Web developer, System developer, Information system student, IT enthusiast, and maybe a Human too. Nice to meet you!
              </p>
              </div>
            </Fade>
            <div className="flex justify-center md:justify-end lg:pr-8 md:pl-3 w-full">
                <button
                    onClick={toAbout}
                    className="flex items-center px-6 py-2 mt-auto font-semibold text-white transition duration-300 ease-in-out rounded-lg shadow-primary-button hover:shadow-none bg-gradient-to-br from-purple-400 hover:from-purple-400 to-purple-700 hover:to-purple-700 focus:shadow-outline focus:outline-none">
                    Know me
                </button>
              {/* <p className="mt-2 text-sm text-center text-gray-600 md:ml-6 md:mt-0 sm:text-left">
                  Bitters chicharrones fanny pack
                  <br className="hidden lg:block" />
                  <a href="#"
                      className="inline-flex items-center font-semibold text-blue-700 md:mb-2 lg:mb-0 hover:text-blue-400 ">
                      Learn More
                      <svg className="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                          width="20" height="20" fill="currentColor">
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                              d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
                      </svg>
                  </a>
              </p> */}
            </div>
          </div>
          <div className="w-1/2 lg:max-w-lg md:w-1/3 md:m-auto text-center">
              <img className="object-cover object-center m-auto" alt="hero"
                  src={person} />
          </div>
        </div>
      </section>

      <div ref={aboutRef} id="about" />
      <AboutMe />                
    </div>
  )
};

export default Home;                                                                                                                                                                                
