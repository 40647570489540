import { connect } from "react-redux";
import { getExperienceThunk } from "thunk/index";
import Experience from "./Experience";

const mapStateToProps = state => ({
  experiences: state.experience.list,
  loading: state.experience.loading
});

const mapActionToProps = {
  getExperienceThunk
}

export default connect(mapStateToProps, mapActionToProps)(Experience);