import { createSlice } from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";

const formSlice = createSlice({
  name: 'general',
  initialState: {
    goingToAbout: false
  },
  reducers: {
    gotoAbout(state) {
      state.goingToAbout = true;
    },
    falseAbout(state) {
      state.goingToAbout =  false;
    }
  },
  extraReducers: {
    [LOCATION_CHANGE]: () => {
      window.scrollTo(0,0);
    },
  }
})

// Extract the action creators object and the reducer
const { reducer, actions } = formSlice;
export const { gotoAbout, falseAbout } = actions;
// Export the reducer, either as a default or named export
export default reducer;