import ProjectItemDummy from 'components/ProjectItem/ProjectItemDummy';
import React, { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import ProjectItem from '../../components/ProjectItem';

const Project = ({projects, getProjectThunk, getTagsThunk}) => {
  useEffect(()=>{
    getProjectThunk();
    getTagsThunk();
  },[getProjectThunk, getTagsThunk]);

  return (
    <div className="bg-gradient-to-br from-bg-1 to-bg-2 pt-10 min-h-screen">
      <section className="text-gray-400 pt-10 body-font">
        <Fade left cascade>
          <div className="container px-5 mx-auto pt-12 text-gray-100">
            <h1 className="mb-8 text-center sm:text-left text-4xl md:text-5xl font-bold">My 
              <span className="text-purple-600"> Projects</span>
            </h1>
            <p className="text-base text-center leading-relaxed sm:text-left text-gray-300 lg:text-1xl">
              Check out awesome projects i've done. There will always be fun stuff to do right?
            </p>
          </div>
        </Fade>
        <div className="container px-5 pb-24 pt-12 mx-auto">
          <div className="col-count-1 sm:col-count-2 lg:col-count-3 xl:col-count-4" >
            {(projects && projects.length!==0) ? projects.map(p=><ProjectItem {...p} key={p.name} />) 
              : [...Array(7)].map((_x, i)=> <ProjectItemDummy key={i} />)
            }
          </div>
        </div>
      </section>

    </div>
  );
}

export default Project;