import OnDevelop from "views/OnDevelop";
import Home from "views/Home";
import Experience from "views/Experience";
import Project from "views/Project";

export default [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/about',
    exact: true,
    component: OnDevelop,
  },
  {
    path: '/experience',
    exact: true,
    component: Experience,
  },
  {
    path: '/project',
    exact: true,
    component: Project,
  },
  {
    path: '/contact',
    exact: true,
    component: OnDevelop,
  },
  {
    component: OnDevelop,
  },
]