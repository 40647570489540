import React, { useEffect } from 'react';
import ExperienceItem from 'components/ExperienceItem';
import ExperienceItemDummy from 'components/ExperienceItem/ExpItemDummy';
import Fade from 'react-reveal/Fade';

const Experience = ({getExperienceThunk, experiences}) => {
  useEffect(()=> {
    getExperienceThunk();
  }, [getExperienceThunk]);

  return (
    <div className="bg-gradient-to-br from-bg-1 to-bg-2 pt-10 min-h-screen">
      <section className="text-gray-100 pt-10 body-font">
        <Fade left cascade>
          <div className="container px-5 mx-auto pt-12 text-gray-100">
            <h1 className="mb-8 text-center sm:text-left text-4xl md:text-5xl font-bold">Exp
              <span className="text-purple-600">eriences</span>
            </h1>
            <p className="text-base text-center leading-relaxed sm:text-left text-gray-300 lg:text-1xl">
              Several remarkable adventure i've been through. Still in long journey ahead.
            </p>
          </div>
        </Fade>
        <div className="container px-5 lg:px-20 pb-24 pt-12 mx-auto flex flex-col z-0">
          {experiences ? experiences.map((exp,i) => 
            <ExperienceItem key={i} pos={i} {...exp} />
          ) : (
            [...Array(3)].map((_x, i)=><ExperienceItemDummy key={i} pos={i}/>)
          )}

          {/* <div className="flex relative pb-20 sm:items-center md:w-2/3 lg:w-1/2 mx-auto lg:mx-0 lg:ml-auto">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
            </div>
            <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white absolute z-0 title-font font-medium text-sm">1</div>
            <div className="flex-grow md:pl-12 pl-10 flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-12 h-12" viewBox="0 0 24 24">
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Shooting Stars</h2>
                <p className="leading-relaxed">VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.</p>
              </div>
            </div>
          </div>

          <div className="flex relative pb-20 sm:items-center md:w-2/3 lg:w-1/2 mx-auto lg:mx-0 lg:mr-auto">
            <div className="h-full w-6 absolute inset-0 lg:inset-x-full flex items-center justify-center">
              <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
            </div>
            <div className="flex-shrink-0 w-6 h-6 rounded-full lg:inset-x-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white absolute z-0 title-font font-medium text-sm">2</div>
            <div className="flex-grow md:pl-12 pl-10 lg:pl-0 lg:pr-6 flex sm:items-center items-start flex-col sm:flex-row lg:flex-row-reverse">
              <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-12 h-12" viewBox="0 0 24 24">
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div className="flex-grow sm:pl-6 mt-6 sm:mt-0 lg:pl-0 lg:pr-6 lg:text-right">
                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">The Catalyzer</h2>
                <p className="leading-relaxed">VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.</p>
              </div>
            </div>
          </div>

          <div className="flex relative pb-20 sm:items-center md:w-2/3 lg:w-1/2 mx-auto lg:mx-0 lg:ml-auto">
            <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
              <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
            </div>
            <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white absolute z-0 title-font font-medium text-sm">3</div>
            <div className="flex-grow md:pl-12 pl-10 flex sm:items-center items-start flex-col sm:flex-row">
              <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-12 h-12" viewBox="0 0 24 24">
                  <circle cx="12" cy="5" r="3"></circle>
                  <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                </svg>
              </div>
              <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">The 400 Blows</h2>
                <p className="leading-relaxed">VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.</p>
              </div>
            </div>
          </div>

          <div className="flex relative pb-20 sm:items-center md:w-2/3 lg:w-1/2 mx-auto lg:mx-0 lg:mr-auto">
            <div className="h-full w-6 absolute inset-0 lg:inset-x-full flex items-center justify-center">
              <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
            </div>
            <div className="flex-shrink-0 w-6 h-6 rounded-full lg:inset-x-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white absolute z-0 title-font font-medium text-sm">4</div>
            <div className="flex-grow md:pl-12 pl-10 lg:pl-0 lg:pr-6 flex sm:items-center items-start flex-col sm:flex-row lg:flex-row-reverse">
              <div className="flex-shrink-0 w-24 h-24 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-12 h-12" viewBox="0 0 24 24">
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div className="flex-grow sm:pl-6 mt-6 sm:mt-0 lg:pl-0 lg:pr-6 lg:text-right">
                <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">Neptunes</h2>
                <p className="leading-relaxed">VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.</p>
              </div>
            </div>
          </div> */}
  
        </div>
      </section>
    </div>
  )
};

export default Experience;