/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import Fade from 'react-reveal/Fade';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';

const ProjectItemDummy = () => {
  return (
    <SkeletonTheme color="#444" highlightColor="#202020">
      <div className="p-4 w-full h-fit inline-block">
        <div className="border border-gray-700 shadow-project-item bg-gray-900 rounded-lg overflow-hidden transform hover:scale-105 transition duration-300">
          <Skeleton className="h-36 max-h-36 md:max-h-unset lg:h-48 md:h-36 w-full object-cover object-center" />
          <div className="p-6">
            <div className="flex flex-wrap">
              <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-2 mr-1 py-1 px-1 border-gray-500 border-2 rounded-full">CATEGORY</h2>            
            </div>
            <Skeleton className="text-lg font-medium mb-3" width='50%' />
            <p className="leading-relaxed mb-3">
              <Skeleton width='100%' />
              <Skeleton width='85%' />
              <Skeleton width='90%' />
              <Skeleton width='80%' />
            </p>
            <div className="flex items-center flex-wrap">
              <a className="text-indigo-400 inline-flex items-center md:mb-2 lg:mb-0">Learn More
                <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  )
}

export default ProjectItemDummy;