import { createSlice } from "@reduxjs/toolkit";
import { getExperienceThunk } from "../../thunk";

const formSlice = createSlice({
  name: 'experience',
  initialState: {
    list: null,
    loading: false,
    error: null,
  },
  extraReducers: {
    [getExperienceThunk.pending]: state => {
      state.loading = true;
    },
    [getExperienceThunk.fulfilled]: (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.list = data && data.sort((exp1, exp2) => exp2.order - exp1.order);
    },
    [getExperienceThunk.rejected]: state => {
      state.loading = false;
    },
  }
})

// Extract the action creators object and the reducer
const { reducer } = formSlice;
// Export the reducer, either as a default or named export
export default reducer;