import { createAsyncThunk } from "@reduxjs/toolkit";
import { getExperience, getProject, getTags } from "api/index";

export const getProjectThunk = createAsyncThunk(
  'project/getProject', async () => {
    const response = await getProject();
    return response;
  }
)

export const getExperienceThunk = createAsyncThunk(
  'experience/getExperience', async () => {
    const response = await getExperience();
    return response;
  }
)

export const getTagsThunk = createAsyncThunk(
  'tags/getTags', async () => {
    const response = await getTags();
    return response;
  }
)