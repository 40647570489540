import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// import { getPersistedReducer } from './persist';
import project from 'store/project';
import experience from 'store/experience';
import tags from 'store/tags';
import general from 'store/general';

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  general,
  project,
  experience,
  tags
})

export default createRootReducer;