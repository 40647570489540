import React from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux';
import store, { history } from './store';
import routes from './routes';
import './App.css';
import { ConnectedRouter } from 'connected-react-router';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

function App() {
  return (
    <Provider store={store}>
        <ConnectedRouter history={history}>
          <Navbar />
          <Switch>
            {routes.map(routeProps => (
              <Route {...routeProps} key={routeProps.path} />
            ))}
          </Switch>
          <Footer />
        </ConnectedRouter>
    </Provider>
  );
}

export default App;
