import React from 'react';
import Fade from 'react-reveal/Fade';

const ExperienceItem = ({pos, name, company, time, logo, desc}) => {
  const type = pos % 2;
  return (
    <>
    { type ? 
      (
        <div className="flex relative pb-20 sm:items-center md:w-2/3 lg:w-1/2 md:mx-auto lg:mx-0 lg:mr-auto">
          <div className="h-full w-6 absolute inset-0 lg:inset-x-full flex items-center justify-center">
            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div className="flex-shrink-0 w-6 h-6 rounded-full lg:inset-x-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 absolute z-0"></div>
          <Fade bottom>
            <div className="flex-grow md:pl-12 pl-10 lg:pl-0 lg:pr-6 flex sm:items-center items-start flex-col sm:flex-row lg:flex-row-reverse">
              
              <div className="flex-shrink-0 w-28 h-28 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center bg-contain bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${logo})`
                }}
              >
              </div>
              <div className="flex-grow sm:pl-6 mt-6 sm:mt-0 lg:pl-0 lg:pr-6 lg:text-right">
                <h2 className="title-font text-purple-700 font-semibold text-3xl">{name}</h2>
                <p className="mb-1 text-base font-semibold">{company} | <span className="font-normal">{time}</span></p>
                <p className="leading-relaxed text-gray-300 text-justify">{desc}</p>
              </div>
            </div>
          </Fade>
        </div> 
      ):(
      <div className="flex relative pb-20 sm:items-center md:w-2/3 lg:w-1/2 md:mx-auto lg:mx-0 lg:ml-auto">
        <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
          <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
        </div>
        <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 absolute z-0"></div>
        <Fade bottom>
          <div className="flex-grow md:pl-12 pl-10 flex sm:items-center items-start flex-col sm:flex-row">
            <div className="flex-shrink-0 w-28 h-28 bg-indigo-100 text-indigo-500 rounded-full inline-flex items-center justify-center bg-contain bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${logo})`
              }}
            >
            </div>
            <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
              <h2 className="title-font text-purple-700 font-semibold text-xl">{name}</h2>
              <p className="mb-1 text-base font-semibold">{company} | <span className="font-normal">{time}</span></p>
              <p className="leading-relaxed text-gray-300 text-justify">{desc}</p>
            </div>
          </div>
        </Fade>
      </div>
    )}
    </>
  );
}

export default ExperienceItem;