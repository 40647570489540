import React, { useEffect, useRef, useState } from 'react';
import ResponsiveButton from './ResponsiveButton';
import leLogo from 'assets/LogoLeMin.png';
import { Link } from 'react-router-dom';
import { gotoAbout } from 'store/general';
import { connect } from 'react-redux';

const Navbar = ({gotoAbout}) => {
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const responsiveMenuRef = useRef();

  useEffect(() => {
    if(responsiveMenuRef.current) {
      if(open) {
        responsiveMenuRef.current.classList.add("right-0");
        responsiveMenuRef.current.classList.remove("-right-full");
      }
      else {
        responsiveMenuRef.current.classList.add("-right-full");
        responsiveMenuRef.current.classList.remove("right-0");
      }
    }
  }, [open]);

  const handleClickOutside = e => {
    if (open && ref && !ref.current.contains(e.target)) {
        setOpen(false);
    }
  }

  window.onclick = function(e) {
    handleClickOutside(e);
  }

  window.onscroll = function(e) {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      if (ref.current) {
        ref.current.classList.remove('-top-20')
        ref.current.classList.add('-top-0')
      }
    } else {
      if (ref.current) {
        ref.current.classList.add('-top-20')
        ref.current.classList.remove('-top-0')
        setOpen(false);
      }
    }
    setPrevScrollpos(currentScrollPos);
  }
  return (
    <>
    <header className='text-gray-400 bg-gradient-to-b from-glass-1 via-glass-2 to-glass-3 back-filter-glass body-font z-20 fixed w-full transition-all duration-500 ease-in-out -top-0' ref={ref}>
      <div className="container mx-auto flex p-5 flex-row items-center">
        <Link to="/" className="flex title-font font-medium items-center text-gray-100 mb-0">
          <img src={leLogo} alt="LE" className="w-8 h-8"/>
          <span className="ml-3 text-xl hidden md:block">Leonardo</span>
        </Link>
        <nav className="hidden md:flex ml-auto flex-wrap items-center text-base justify-center">
          <Link onClick={()=>{setOpen(false);gotoAbout();}} to="/" className="mr-5 hover:text-gray-100 cursor-pointer">About</Link>
          <Link onClick={()=>setOpen(false)} to="/experience" className="mr-5 hover:text-gray-100 cursor-pointer">Experiences</Link>
          <Link onClick={()=>setOpen(false)} to="/project" className="mr-5 hover:text-gray-100 cursor-pointer">Projects</Link>
          <Link onClick={()=>setOpen(false)} to="/contact" className="mr-5 hover:text-gray-100 cursor-pointer">Contact</Link>
        </nav>
        <button className="md:hidden inline-flex items-center ml-auto bg-none border-0 focus:outline-none">
          <ResponsiveButton open={open} setOpen={setOpen} />
        </button>
      </div>
    </header>
      <nav ref={responsiveMenuRef} className="text-gray-100 flex md:hidden flex-col h-screen items-center text-base justify-center w-full fixed -right-full bg-gradient-to-br from-glass-5 to-glass-4 back-filter-glass transition-all duration-200 z-10">
        <Link onClick={()=>{setOpen(false);gotoAbout();}} to="/" className="py-5 hover:text-gray-100 cursor-pointer font-bold w-full text-center hover:bg-glass-1">About</Link>
        <Link onClick={()=>setOpen(false)} to="/experience" className="py-5 hover:text-gray-100 cursor-pointer font-bold w-full text-center hover:bg-glass-1">Experiences</Link>
        <Link onClick={()=>setOpen(false)} to="/project" className="py-5 hover:text-gray-100 cursor-pointer font-bold w-full text-center hover:bg-glass-1">Projects</Link>
        <Link onClick={()=>setOpen(false)} to="/contact" className="py-5 hover:text-gray-100 cursor-pointer font-bold w-full text-center hover:bg-glass-1">Contact</Link>
      </nav>
      </>
  )
}

export default connect(null, {gotoAbout})(Navbar);