/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Fade from 'react-reveal/Fade';

const ProjectItem = ({name, tag, desc, thumbnail, tagList, link }) => {
  return (
    <Fade bottom>
    <div className="p-4 w-full h-fit inline-block">
      <div className="border border-gray-700 shadow-project-item bg-gray-900 rounded-lg overflow-hidden transform hover:scale-105 transition duration-300">
        <img className="max-h-36 md:max-h-unset lg:h-48 md:h-36 w-full object-cover object-center" src={thumbnail || "https://dummyimage.com/721x401"} alt="blog" />
        <div className="p-6">
          <div className="flex flex-wrap">
            {tag && tagList ? tag.map(tg=>{
              const t = tagList[tg];
              return (<h2 className={`tracking-widest text-xs title-font opacity-80 font-medium text-${t.color}-500 mb-2 mr-1 py-1 px-2 border-${t.color}-500 border-2 rounded-full`} key={t.name}>{t.name}</h2>)
            })
            : (
              <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-2 mr-1 py-1 px-1 border-gray-500 border-2 rounded-full">CATEGORY</h2>
            )}
          </div>
          <h1 className="title-font text-lg font-medium text-white mb-3">{name || 'The 400 Blows'}</h1>
          <p className="leading-relaxed mb-3">{desc || 'Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.'}</p>
          {link && <div className="flex items-center flex-wrap">
            <a className="text-indigo-400 inline-flex items-center md:mb-2 lg:mb-0" {...(link && {'href': link})}>Check it Out
              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>}
        </div>
      </div>
    </div>
    </Fade>
  )
}

export default ProjectItem;