import React from 'react';
import myPhoto from 'assets/myPhoto.jpg';

const AboutMe = () => {
  return (
    <section className="text-gray-100 body-font bg-gray-800">
      <h1 className="mb-10 text-center text-4xl md:text-5xl px-5 pt-16 font-bold mx-auto">About Me</h1>
      <div className="container flex flex-col md:flex-row-reverse justify-center items-center px-5 pb-16 mx-auto lg:px-20">
        <div className="flex flex-col items-center self-center w-full pt-0 mb-16 lg:flex-grow max-w-md md:w-1/2 lg:pl-24 md:pl-0 md:items-start md:mb-0">
          <p className="text-base leading-relaxed text-justify text-gray-100 lg:text-1xl">
            <>
              Hello again! my name is Leonardo an IT developer based in Jakarta. 
              I develop websites, applications, even integrated systems for organizational or business needs. 
              Taking Information System major in University of Indonesia.
            </>
            <br />
            <br />
            <>
              Having great interest in IT development, i found IT/IS has significant impact on businesses, operations, even lifestyles. 
              So i decided to contribute in IT world. Perharps i could help businesses, organization or people to achieve 
              their goals through the use of IT/IS.
            </>
          </p>
        </div>
        <div className="md:m-auto sm:w-1/2 lg:max-w-xs lg:m-4 md:w-1/3 text-center">
          <img className="object-cover object-top m-auto rounded-lg h-56 w-2/3 sm:w-full md:h-auto" alt="hero" src={myPhoto} />
        </div>
      </div>
    </section>                  
  )
};

export default AboutMe;                                                                                                                                                                                
