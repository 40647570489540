/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Footer = () => (
  <footer className="text-gray-400 bg-gray-900 body-font">
    {/* <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
      <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
        <a className="flex title-font font-medium items-center md:justify-start justify-center text-white">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
          </svg>
          <span className="ml-3 text-xl">Tailblocks</span>
        </a>
        <p className="mt-2 text-sm text-gray-500">Air plant banjo lyft occupy retro adaptogen indego</p>
      </div>
      <div className="flex-grow flex flex-wrap md:pr-20 -mb-10 md:text-left text-center order-first">
        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">CATEGORIES</h2>
          <nav className="list-none mb-10">
            <li>
              <a className="text-gray-400 hover:text-white">First Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Second Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Third Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Fourth Link</a>
            </li>
          </nav>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">CATEGORIES</h2>
          <nav className="list-none mb-10">
            <li>
              <a className="text-gray-400 hover:text-white">First Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Second Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Third Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Fourth Link</a>
            </li>
          </nav>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">CATEGORIES</h2>
          <nav className="list-none mb-10">
            <li>
              <a className="text-gray-400 hover:text-white">First Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Second Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Third Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Fourth Link</a>
            </li>
          </nav>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">CATEGORIES</h2>
          <nav className="list-none mb-10">
            <li>
              <a className="text-gray-400 hover:text-white">First Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Second Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Third Link</a>
            </li>
            <li>
              <a className="text-gray-400 hover:text-white">Fourth Link</a>
            </li>
          </nav>
        </div>
      </div>
    </div> */}
    <div className="bg-gray-800 bg-opacity-75">
      <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p className="text-gray-400 text-sm text-center sm:text-left">© 2021 — Leonardo
          {/* <a className="text-gray-500 ml-1" rel="noopener noreferrer" target="_blank">@knyttneve</a> */}
        </p>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
          {/* <a className="text-gray-400" rel="noopener noreferrer" target="_blank">
            <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>
          <a className="ml-3 text-gray-400" rel="noopener noreferrer" target="_blank">
            <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
            </svg>
          </a> */}
          <a href="https://github.com/leleonardo81/" className="text-gray-400" rel="noopener noreferrer" target="_blank">
            <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 16 16" version="1.1">
              <path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
            </svg>
          </a>
          <a href="https://gitlab.com/Leonardo81/" className="ml-3 text-gray-400" rel="noopener noreferrer" target="_blank">
            <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" className="w-5 h-5">
              <path className="transform scale-50" d="M38.012 4a1.004 1.004 0 0 0-.965.691L32.074 20H17.926L12.953 4.691a1.008 1.008 0 0 0-.93-.691 1.007 1.007 0 0 0-.96.648L5.07 20.641c-.004 0-.004.004-.008.007l-3 8a1 1 0 0 0 .352 1.16l21.992 15.993.004.008c.004 0 .004 0 .004.004l.04.023c.015.008.026.02.042.027.004 0 .004.004.008.004 0 0 .004.004.008.004.004.004.008.008.015.008l.016.012c.02.007.043.02.066.027v.004l.067.023c.004 0 .004 0 .008.004.015.004.03.008.05.016.008 0 .016.004.028.004 0 .004 0 .004 0 0 .02.008.039.011.058.015l.078.012c.04 0 .082.004.122.004.039-.004.074-.004.109-.012.016 0 .027 0 .043-.004h.008c.008-.004.011-.004.02-.004.003 0 .007-.003.015-.003.008-.004.02-.004.027-.008a.491.491 0 0 0 .04-.012c.007 0 .011 0 .015-.004h.012a.636.636 0 0 0 .102-.043c.003 0 .003 0 .007-.004.004 0 .008 0 .012-.004l.023-.011a.894.894 0 0 0 .063-.036c.007-.003.011-.007.015-.011.004 0 .008-.004.012-.004.016-.012.031-.02.047-.031l.008-.012L47.59 29.809c.363-.266.504-.739.347-1.16l-2.992-7.973a.072.072 0 0 1-.008-.028l-6-16A1.001 1.001 0 0 0 38.013 4zM8.066 22h8.407l5.855 18.016zm25.461 0h8.407L27.672 40.016zM6.312 23.008l13.375 16.894L4.204 28.641zm37.376 0l2.109 5.633-15.484 11.261z" id="surface1"></path>
            </svg>
          </a>
          <a href="https://instagram.com/leonardo7820/" className="ml-3 text-gray-400" rel="noopener noreferrer" target="_blank">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </a>
          <a href="https://www.linkedin.com/in/leonardo-leo-5a5845175/" className="ml-3 text-gray-400" rel="noopener noreferrer" target="_blank">
            <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
              <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
              <circle cx="4" cy="4" r="2" stroke="none"></circle>
            </svg>
          </a>
        </span>
      </div>
    </div>
  </footer>
);

export default Footer;