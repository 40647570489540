// import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { falseAbout, gotoAbout } from 'store/general/index';
import Home from './Home';

const mapStateToProps = state => ({
  goingToAbout: state.general.goingToAbout
})

const mapActionToProps = {
  gotoAbout,
  falseAbout
}
export default connect(mapStateToProps, mapActionToProps)(Home);