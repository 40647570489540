import { connect } from "react-redux";
import { getProjectThunk, getTagsThunk } from "thunk/index";
import Project from "./Project";

const mapStateToProps = state => ({
  projects: state.project.list,
  loading: state.project.loading
});

const mapActionToProps = {
  getTagsThunk,
  getProjectThunk
}

export default connect(mapStateToProps, mapActionToProps)(Project);